import { useState } from 'react';

const MiniHeaderGamesSendMessages = () => {
  const [messageStatus, setMessageStatus] = useState('');

  const validMessageTypes = new Set([
	'PAUSE_DEMO',
	]);

  const iframeId = 'iog-mini-header-game';
  
  // Function to send messages to the game iframe using ID
  const sendMessageToGame = (messageType, data = {}) => {
    // Get iframe element by ID
    const iframeElement = document.getElementById(iframeId);
    
    if (iframeElement && iframeElement.contentWindow) {
      const payload = {
        type: validMessageTypes.has(messageType) ? messageType : 'unknown',
        data: data,
        timestamp: new Date().getTime()
      };
      // Extract origin from gameUrl for security
      let targetOrigin;
      try {
        targetOrigin = new URL(gameUrl).origin;
      } catch (e) {
        // If URL parsing fails, use wildcard (less secure but more flexible)
        console.warn('Invalid game URL format, using wildcard origin');
        targetOrigin = '*';
      }
      
      // Send the message to the iframe
      iframeElement.contentWindow.postMessage(payload, targetOrigin);
      setMessageStatus(`Message sent: ${messageType}`);
      console.log(`Message sent to game: ${messageType}`, data);
      
      return true;
    } else {
      setMessageStatus(`Error: Game iframe with ID "${iframeId}" not found or not available`);
      console.error(`Game iframe with ID "${iframeId}" not found or not available`);
      return false;
    }
  };
  
  return {
    sendMessageToGame,   // Function to send messages
    messageStatus        // Current status of messaging
  };
};

export default MiniHeaderGamesSendMessages;