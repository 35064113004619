import { If, For } from '@twocatmoon/react-template-helpers';
import { Slot, findSlots } from '@twocatmoon/react-slot';
import { use, useEffect, useRef } from 'react';
import MiniHeaderGamesSendMessages from '@lib/components/MiniHeaderGamesSendMessages';

import log from './dev/log';

export { If, For, Slot, findSlots };

export function statesFromProps (rootClassName, states, props) {
  const classNames = [];

  if (states) { // Only apply states if they exist in list of available states and are truthy
    states.forEach((state) => {
      if (props[state]) classNames.push(`${rootClassName}--${state}`);
    });
  } else { // Always apply states if they are truthy
    Object.keys(props).forEach((key) => {
      if (props[key]) classNames.push(`${rootClassName}--${key}`);
    });
  }

  return classNames;
}

/* 
  Usage examples:
  -----
  const className = joinClassNames('Foo', 'Bar', undefined, '--hideMobile'); // 'Foo Bar --hideMobile'
*/
export function joinClassNames () {
  const args = Array.from(arguments);

  return args
    .filter((arg) => !!arg)
    .map((arg) => arg.trim())
    .join(' ')
    .trim();
}

/* 
  Usage examples:
  -----
  const className = toClassName('Button', '--hideMobile'); // 'Button --hideMobile'
  const className = toClassName('Button', '&--primary', '&--loading'); // 'Button Button--primary Button--loading' -- &-- signals the function that the state belongs to the root class name
  const className = toClassName('Button', '&--primary', [ 'loading' ], props); // 'Button Button--primary Button--loading' -- props.loading must be true
  const className = toClassName('Button', '&--primary', { loading: isLoading }); // 'Button Button--primary Button--loading' -- no array of states present, loading will always be added if isLoading is true
*/
export function toClassName () {
  const args = Array
    .from(arguments)
    .filter((arg) => !!arg);

  // First argument is always the root class name
  const rootClassName = args.shift();

  // If last argument is object, set props
  let props = {};
  if (!Array.isArray(args[args.length - 1]) && typeof args[args.length - 1] === 'object') props = args.pop();

  // If second last argument is array, set states
  // If no states are set, _all_ states present in props will be added if their conditions are met
  let states;
  if (Array.isArray(args[args.length - 1])) states = args.pop();

  // Filter and map
  const classNames = args
    .reduce((acc, arg) => ([
      ...acc,
      ...arg.split(' ').filter((part) => !!part && part !== rootClassName)
    ]), [])
    .map((arg) => {
      // Shortcut for adding static/active states eg. `Button--active` from `toClassName('Button', '&--active')`
      if (arg.startsWith('&--')) return `${rootClassName}${arg.replace('&', '')}`
      return arg
    });

  return [
    ...new Set([ // remove duplicates with Set destructuring
      rootClassName,
      ...statesFromProps(rootClassName, states, props),
      ...classNames
    ])
  ]
    .filter((name) => !!name)
    .join(' ')
    .trim();
}

// export function createComponent (name, options, componentFn, auditRenders) {
//   return function (props) {
//     if (auditRenders && process.env.NODE_ENV === 'development') {
//       log(`${name} render`);
//     }

//     const context = {};

//     // Root class name
//     /*
//       Usage example:
//       -----
//       createComponent('Dropdown', { classStates: [ 'active' ]}, function Dropdown ({ className }, props) {
//         return <div className={className} />
//       })
//       ...
//       <Dropdown className=‘--hideMobile’ active />
//       ...
//       className === 'Dropdown Dropdown--active --hideMobile'
//     */
//     let classStates = [];
//     if (options.classStates || props.classStates) {
//       classStates = [
//         ...(options.classStates || []),
//         ...(props.classStates || [])
//       ];
//     }
//     context.className = toClassName(name, props.className, classStates, props);

//     // Styles
//     context.style = props.style || {};

//     // Slots
//     if (props.children) context.slots = findSlots(props.children);

//     // Class Helper
//     /* 
//       Usage examples:
//       -----
//       createComponent('Dropdown', {}, function Dropdown ({ mergeClassNames }) {
//         const className = mergeClassNames('--hideMobile', '--hideTablet');
//         // className === 'Dropdown --hideMobile --hideTablet'

//         return <div className={className} />
//       })
//       ...
//       createComponent('Dropdown', {}, function Dropdown ({ mergeClassNames }) {
//         const [ menuState ] = useState(true);
//         const className = mergeClassNames(toClassName('Dropdown', { active: menuState }));
//         // className === 'Dropdown Dropdown--active'

//         return <div className={className} />
//       })
//     */
//     context.mergeClassNames = function () {
//       const args = Array.from(arguments);
//       const classNames = args
//         .filter((arg) => !!arg)
//         .map((arg) => arg.replace(name, '').trim())
//         .join(' ')
//         .trim();

//       return [context.className, classNames].join(' ').trim();
//     };

//     return componentFn(context, props);
//   }
// }

// export function createPage (name, options, componentFn) {
//   return createComponent(name, options, componentFn, true)
// }


export function createComponent (name, options, componentFn, auditRenders) {
	return function (props) {
	  if (auditRenders && process.env.NODE_ENV === 'development') {
		log(`${name} render`);
	  }
  
	  const context = {};
  
	  // Root class name
	  /*
		Usage example:
		-----
		createComponent('Dropdown', { classStates: [ 'active' ]}, function Dropdown ({ className }, props) {
		  return <div className={className} />
		})
		...
		<Dropdown className='--hideMobile' active />
		...
		className === 'Dropdown Dropdown--active --hideMobile'
	  */
	  let classStates = [];
	  if (options.classStates || props.classStates) {
		classStates = [
		  ...(options.classStates || []),
		  ...(props.classStates || [])
		];
	  }
	  context.className = toClassName(name, props.className, classStates, props);
  
	  // Styles
	  context.style = props.style || {};
  
	  // Slots
	  if (props.children) context.slots = findSlots(props.children);
  
	  // Class Helper
	  /* 
		Usage examples:
		-----
		createComponent('Dropdown', {}, function Dropdown ({ mergeClassNames }) {
		  const className = mergeClassNames('--hideMobile', '--hideTablet');
		  // className === 'Dropdown --hideMobile --hideTablet'
  
		  return <div className={className} />
		})
		...
		createComponent('Dropdown', {}, function Dropdown ({ mergeClassNames }) {
		  const [ menuState ] = useState(true);
		  const className = mergeClassNames(toClassName('Dropdown', { active: menuState }));
		  // className === 'Dropdown Dropdown--active'
  
		  return <div className={className} />
		})
	  */
	  context.mergeClassNames = function () {
		const args = Array.from(arguments);
		const classNames = args
		  .filter((arg) => !!arg)
		  .map((arg) => arg.replace(name, '').trim())
		  .join(' ')
		  .trim();
  
		return [context.className, classNames].join(' ').trim();
	  };
  
	  return componentFn(context, props);
	}
  }
  
  export function createPage(name, options, componentFn) {
	return function PageComponent(props) {
	  const iframeRef = useRef(null);
	  const { sendMessageToGame } = MiniHeaderGamesSendMessages();
  
	  useEffect(() => {
		const iframe = document.getElementById('iog-mini-header-game');
  
		if (['PlayPage', 'NewPage', 'CategoryPage'].includes(name)) {
			
		  const handleLoad = () => sendMessageToGame('PAUSE_DEMO');
  
		  if (iframe) {
			iframe.onload = handleLoad;
		  } else if (iframeRef.current) {
			iframeRef.current.onload = handleLoad;
		  }
		}
	  }, [sendMessageToGame]);
  
	  const classStates = [
		...(options.classStates || []),
		...(props.classStates || []),
	  ];
  
	  const context = {
		className: toClassName(name, props.className, classStates, props),
		style: props.style || {},
		slots: props.children ? findSlots(props.children) : undefined,
		iframeRef,
		mergeClassNames: (...args) =>
		  [context.className, ...args.filter(Boolean).map(arg => arg.replace(name, '').trim())]
			.join(' ')
			.trim(),
	  };
  
	  if (options.auditRenders && process.env.NODE_ENV === 'development') {
		log(`${name} render`);
	  }
  
	  return componentFn(context, props);
	};
  }


