export function GoogleTagManager({ clientId }) {
	const script = `(
	  function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({
		  'gtm.start': new Date().getTime(),
		  event: 'gtm.js'
		});
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l!='dataLayer' ? '&l=' + l : '';
  
		j.async = true;
		j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
  
		// Define gtag function and initialize GA
		w.dataLayer = w.dataLayer || [];
		function gtag(){ w.dataLayer.push(arguments); }
		w.gtag = gtag;
		gtag('js', new Date());
		gtag('config', i);
	  }
	)(window, document, 'script', 'dataLayer', '${clientId}');`;
  
	return (
	  <>
		<script dangerouslySetInnerHTML={{ __html: script }} />
	  </>
	);
  }

export function GoogleTagManagerNoscript ({ clientId }) {
  return (
    <>
      <noscript>
        <iframe 
          src={`https://www.googletagmanager.com/ns.html?id=${clientId}`}
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}
