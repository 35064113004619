import Head from 'next/head';
import { useRouter } from 'next/router';
import getMetaDescription from '@lib/util/getMetaDescription';

/**
 * Meta component for managing head meta content
 * Handles page-specific metadata and prevents duplicate tags
 * 
 * @param {Object} props - Component props
 * @param {string} props.title - Page title (will be appended to site title)
 * @param {string} props.description - Page description (overrides default)
 * @param {string} props.keywords - Page keywords (overrides default)
 * @param {string} props.author - Page author (overrides default)
 * @param {Object} props.openGraph - Open Graph specific props
 * @param {string} props.openGraph.title - OG title
 * @param {string} props.openGraph.description - OG description
 * @param {string} props.openGraph.image - OG image URL
 * @param {string} props.openGraph.type - OG type (default: website)
 * @param {boolean} props.noIndex - Set to true to add noindex directive
 * @param {React.ReactNode} props.children - Additional head elements
 * @param {boolean} props.structuredData - Set to true when structured data is included in children
 * @param {Array} props.alternateUrls - Array of language alternate URLs [{lang: 'en', url: 'https://example.com/en'}]
 */
export default function Meta({
  title,
  description,
  keywords,
  author,
  openGraph = {},
  noIndex = false,
  children,
  structuredData = false,
  alternateUrls = []
}) {
  const router = useRouter();
  
  // Process description for proper length and formatting
  const processedDescription = description ? getMetaDescription(description) : null;
  const openGraphDescription = openGraph?.description ? getMetaDescription(openGraph.description) : null;
  
  // Use provided values or fall back to environment variables
  const pageTitle = title 
    ? `${title} | ${process.env.APP_CONFIG.APP_TITLE}`
    : process.env.APP_CONFIG.APP_TITLE;
    
  const pageDescription = processedDescription || process.env.APP_CONFIG.DESCRIPTION;
  const pageKeywords = keywords || process.env.APP_CONFIG.KEYWORDS;
  const pageAuthor = author || process.env.APP_CONFIG.APP_TITLE;
  
  // Open Graph
  const ogTitle = openGraph.title || title || process.env.APP_CONFIG.APP_TITLE;
  const ogDescription = openGraphDescription || processedDescription || process.env.APP_CONFIG.DESCRIPTION;
  const ogImage = openGraph.image || null;
  const ogType = openGraph.type || 'website';
  const ogUrl = `${process.env.APP_CONFIG.URL}${router.asPath}`;

  return (
    <Head>
      {/* Core Meta Tags */}
      <meta charSet="utf-8" key="charset" />
      <meta 
        name="viewport" 
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no" 
        key="viewport"
      />
      <meta httpEquiv="content-language" content="en" key="language" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" key="ie-compat" />
      <meta httpEquiv="audience" content="General" key="audience" />
      <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" key="csp" />
      
      {/* Search Engine Directives */}
      {noIndex && <meta name="robots" content="noindex, nofollow" key="robots" />}
      
      {/* Title & Description */}
      <title key="title">{pageTitle}</title>
      <meta name="title" content={pageTitle} key="meta-title" />
      <meta name="description" content={pageDescription} key="description" />
      <meta name="author" content={pageAuthor} key="author" />
      <meta name="keywords" content={pageKeywords} key="keywords" />
      <meta name="news_keywords" content={pageKeywords} key="news-keywords" />
      <meta name="distribution" content="Global" key="distribution" />
      
      {/* Open Graph / Social */}
      <meta property="og:title" content={ogTitle} key="og-title" />
      <meta property="og:description" content={ogDescription} key="og-description" />
      <meta property="og:url" content={ogUrl} key="og-url" />
      <meta property="og:type" content={ogType} key="og-type" />
      {ogImage && <meta property="og:image" content={ogImage} key="og-image" />}
      
      {/* Twitter Card Data */}
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
      <meta name="twitter:title" content={ogTitle} key="twitter-title" />
      <meta name="twitter:description" content={ogDescription} key="twitter-description" />
      {ogImage && <meta name="twitter:image" content={ogImage} key="twitter-image" />}
      
      {/* Canonical URL */}
      <link rel="canonical" href={ogUrl} key="canonical" />
      <link rel="shortlink" href={ogUrl} key="shortlink" />
      
      {/* Language alternates */}
      {alternateUrls.map((alt) => (
        <link 
          rel="alternate" 
          hrefLang={alt.lang} 
          href={alt.url} 
          key={`alternate-${alt.lang}`} 
        />
      ))}
      
      {/* Favicons */}
      <link href="/images/app/favicon-16.png" rel="icon" type="image/png" sizes="16x16" key="favicon-16" />
      <link href="/images/app/favicon-32.png" rel="icon" type="image/png" sizes="32x32" key="favicon-32" />
      <link href="/images/app/favicon-48.png" rel="icon" type="image/png" sizes="48x48" key="favicon-48" />
      <link 
        href="/images/app/favicon-48.png" 
        rel="mask-icon" 
        color={process.env.APP_CONFIG.THEME} 
        key="mask-icon" 
      />
      
      {/* Orientation */}
      <meta name="screen-orientation" content="portrait" key="screen-orientation" />
      
      {/* Android */}
      <meta name="theme-color" content={process.env.APP_CONFIG.THEME} key="theme-color" />
      <meta name="mobile-web-app-capable" content="yes" key="mobile-web-app-capable" />
      <link href="/images/app/icon-128.png" rel="icon" sizes="128x128" key="icon-128" />
      <link href="/images/app/icon-192.png" rel="icon" sizes="192x192" key="icon-192" />
      
      {/* iOS */}
      <meta name="apple-mobile-web-app-title" content={process.env.APP_CONFIG.DEFAULT_TITLE} key="apple-app-title" />
      <meta name="apple-mobile-web-app-capable" content="yes" key="apple-app-capable" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" key="apple-status-bar" />
      <link href="/images/app/icon-76.png" rel="apple-touch-icon" key="apple-icon" />
      <link href="/images/app/icon-76.png" rel="apple-touch-icon" sizes="76x76" key="apple-icon-76" />
      <link href="/images/app/icon-120.png" rel="apple-touch-icon" sizes="120x120" key="apple-icon-120" />
      <link href="/images/app/icon-152.png" rel="apple-touch-icon" sizes="152x152" key="apple-icon-152" />
      <link href="/images/app/splash-1024.png" rel="apple-touch-startup-image" key="apple-splash" />
      
      {/* Additional head elements */}
      {children}
    </Head>
  );
}