export function getMetaDescription (text) {
	if (!text) return '';

	const MAX_LENGTH = 160; // Ideal SEO meta description length
	const TRUNCATE_AT = MAX_LENGTH - 3; // Account for ellipsis

	return text.length > MAX_LENGTH 
		? `${text.slice(0, TRUNCATE_AT)}...`
		: text;
};

export default getMetaDescription;