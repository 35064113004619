import { useEffect } from 'react';

export function PostMessageListener() {
  useEffect(() => {
    const allowedDomain = 'iogames.space';
	const validGames = new Map([
		[0, 'mrSword'],
		[1, 'roidz'],
	])

    // Mapping numeric values to meaningful strings
    const validTypes = new Map([
      [0, 'mini_game'],
    ]);

    const validActions = new Map([
      [0, 'firstGame'],
      [1, 'retry'],
    ]);

    const messageHandler = (event) => {
      try {
        // Validate event origin
        const eventURL = new URL(event.origin);
        if (!eventURL.hostname.endsWith(`.${allowedDomain}`) && eventURL.hostname !== allowedDomain) {
          console.warn("Blocked message from unauthorized origin:", event.origin);
          return;
        }

        const { data } = event;
        if (!data || typeof data !== 'object') return;

        // Ensure `type` is a valid number
        if (!validTypes.has(data.type)) return;

        const typeString = validTypes.get(data.type);
		
        const { title, action } = data.message || {};
        // Ensure `action` is a valid number
        if (!validActions.has(action)) return;

        const actionString = validActions.get(action);
		const gameName = validGames.get(title);

		if (typeString === 'mini_game') {
			gtag('event', typeString, {
				game_name: gameName,
				play: actionString,
			});
		}

      } catch (error) {
        console.error("Invalid event or malformed data:", event, error);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return null; // No UI needed
}