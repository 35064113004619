import Head from 'next/head';
import { useRouter } from 'next/router';

import storage from '@lib/util/storage';
import useModal from '@lib/hooks/modal';
import useNotifications from '@lib/hooks/notifications';
import useEffectOnce from '@lib/hooks/effectOnce';
import shared from '@lib/components';

import Meta from '@lib/components/head/Meta';

export default function App (props) {
  const {
    Page,
    Modal,
    Notification,
    DocumentTitle,
    ComScore,
    GoogleTagManager, 
    GoogleTagManagerNoscript,
	PostMessageListener,
  } = shared;

  const { useAuthStore, verifyAction, readyAction } = props;
  const [ authState, authDispatch ] = useAuthStore();
  const router = useRouter();

  useEffectOnce(
    () => {
      const cached = storage.getLocal('auth');
      if (authState.auth || cached?.auth) {
        if (authState.auth) {
          authDispatch(verifyAction);
          return true;
        }
      } else {
        authDispatch(readyAction);
        return true;
      }
    }, 
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [authState.auth]
  );

  return (
    <>
      <DocumentTitle />

      <Meta>
        {/* Analytics */}
        <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
        <GoogleTagManager clientId={process.env.APP_CONFIG.GOOGLE_ANALYTICS_ID} />
      </Meta>

      <GoogleTagManagerNoscript clientId={process.env.APP_CONFIG.GOOGLE_ANALYTICS_ID} />

	  <PostMessageListener />

      <Page theme={props.theme || 'default'}>
        <Modal.Modals />
        <Notification.Notifications />
        <div className='App'>
          {props.children}
        </div>
      </Page>
    </>
  );
}

App.Main = function AppMain (props) {
  return (
    <main className={[ 'App__Main', props.padding && 'App__Main--padding' ].join(' ')}>
      {props.children}
    </main>
  );
};

App.Header = function AppHeader (props) {
  return (
    <header className={'App__Header'}>
      {props.children}
    </header>
  );
};

App.Footer = function AppFooter (props) {
  return (
    <footer className={'App__Footer'}>
      {props.children}
    </footer>
  );
};

App.Provider = function AppProvider (props) {
  const { Provider: ModalProvider } = useModal();
  const { Provider: NotificationsProvider } = useNotifications();

  return (
    <>
      <ModalProvider>
        <NotificationsProvider>
          {
            props.authProvider
              ? <props.authProvider>{props.children}</props.authProvider>
              : props.children
          }
        </NotificationsProvider>
      </ModalProvider>
    </>
  );
};
