import { createStore, CACHE_STRATEGY_LOCAL } from '@lib/util/store';

// Mutations

function SET_COLLAPSED (state, payload) {
  return {
    collapsed: payload
  }
}

// Actions

export function setCollapsed (payload, mutate) {
  mutate(SET_COLLAPSED, payload);
}

// Export

// set categories menu collapsed to false
export const {
  Provider: MenuProvider,
  useStore: useMenuStore
} = createStore({
  defaultState: {
    collapsed: false
  },
  cacheKey: 'menu',
  cacheStrategy: CACHE_STRATEGY_LOCAL
});
